
.container-fluid {
  margin-top: -5% !important;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.card-hover {
  border: 2px solid rgba(0, 0, 0, .3);
  /* box-shadow: 0px 3px 6px #00000029; */
}

.active-react {
  border: 2px solid #53C1DE;
  box-shadow: 0px 3px 6px #53C1DE;
}

.card-hover:hover.react {
  border: 2px solid #53C1DE;
  box-shadow: 0px 3px 6px #53C1DE;
}

.active-angular {
  border: 2px solid #DD0031;
  box-shadow: 0px 3px 6px #DD0031;
}

.card-hover:hover.angular {
  border: 2px solid #DD0031;
  box-shadow: 0px 3px 6px #DD0031;
}

.active-vanilla {
  border: 2px solid #f7df1e;
  box-shadow: 0px 3px 6px #f7df1e;
}

.card-hover:hover.vanillajs {
  border: 2px solid #f7df1e;
  box-shadow: 0px 3px 6px #f7df1e;
}

.card-img-top {
  max-width: 200px !important;
  margin: auto;
}

